<template>
	<Header></Header>
	<div class="loginBox" :style="{'margin-top':marginStyle,'margin-bottom':marginStyle}">
		<div class="title">账号登录</div>
		<div style="margin-bottom:15px;">输入手机号码后，未注册手机号将自动注册账号。</div>
		<div class="login">
			<el-form
				:model="ruleFormPass"
				:rules="rulesPass"
				ref="ruleFormPass"
				label-position="top"
				class="ruleForm"
				v-if="isPass"
			>
				<el-form-item label="手机号" prop="urMobile">
				  <el-input v-model="ruleFormPass.urMobile" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="urPassword">
				  <el-input type="password" v-model="ruleFormPass.urPassword" autocomplete="off" placeholder="请输入密码"></el-input>
				</el-form-item>
				<div style="width:450px;height:50px;">
					<div v-if="passName == '验证码登录'" style="width:450px;height:50px;" id="login"></div>
				</div>
				
				<el-form-item>
				  <el-button type="primary" @click="submitFormPass('ruleFormPass')" :disabled="disabled" round style="width:100%" :loading="loadingLogin">登录</el-button>
				</el-form-item>
			</el-form>
			<el-form
				:model="ruleFormCode"
				:rules="rulesCode"
				ref="ruleFormCode"
				label-position="top"
				class="ruleForm"
				v-else
			>
				<el-form-item label="手机号" prop="ceMobile">
				  <el-input v-model="ruleFormCode.ceMobile" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="code">
				  <div style="display: flex;">
				    <div style="flex:1;width:344px;">
				      <el-input v-model="ruleFormCode.code" autocomplete="off" placeholder="请输入手机验证码"></el-input>
				    </div>
				    <div style="margin-left:4px;">
				      <el-button type="primary" :disabled="disableSend" @click="slideBtn">{{sendText}}</el-button>
				    </div>
				  </div>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" @click="submitForm('ruleFormCode')" round style="width:100%" :loading="loading">登录</el-button>
				</el-form-item>
			</el-form>
			<div class="passBox">
				<div class="pass loginPass" @click="isPass = !isPass">{{passName}}</div>
				<div class="pass findPass" @click="findPass">找回密码</div>
			</div>
			<div style="margin-top:20px;">
				<el-checkbox v-model="isBreak" v-if="!isPass">
					您已阅读并同意《用户协议》《隐私政策》
				</el-checkbox>
			</div>
		</div>
	</div>
	<Footer></Footer>
	<el-dialog
		width="500px"
		top="30vh"
		title="请按住滑块，拖动到最右边"
		v-model="dialogVisible"
	    :before-close="handleClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:destroy-on-close="true"
		custom-class="dialog"
	>
		<div id="nc"></div>
	</el-dialog>
</template>

<script>
	import Header from "../components/Header"
	import Footer from "../components/Footer"
	export default {
		components:{Header,Footer},
		data(){
			const validateMobile = (rule,value, callback) => {
				if(!(/^1[345789]\d{9}$/.test(value))){ 
					callback(new Error('请输入正确的手机号码'))
				} else {
					callback()
				}
			}
			const validatePassMobile = (rule,value, callback) => {
				if(!(/^1[345789]\d{9}$/.test(value))){ 
					callback(new Error('请输入正确的手机号码'))
				} else {
					callback()
				}
			}
			return{
				marginStyle:'',
				disabled:true,
				isBreak:false,
				isPass:false,
				loadingLogin:false,
				dialogVisible:false,
				passName:'密码登录',
				ruleFormCode:{
					ceMobile: '',
					code:'',
				},
				ruleFormPass:{
					urMobile: '',
					urPassword:'',
					sessionId:'',
					sig:'',
					token:'',
				},
				rulesCode: {
					ceMobile: [
						{ required: true, validator: validateMobile, trigger: 'change' }
					],
					code: [
						{ required: true, message: '手机验证码不能为空', trigger: 'change' }
					]
				},
				rulesPass: {
					urMobile: [
						{ required: true, validator: validatePassMobile, trigger: 'change' }
					],
					urPassword: [
						{ required: true, message: '密码不能为空', trigger: 'change' }
					]
				},
				disableSend:false,
				sendText:'发送验证码',
				sendTime: 120,
				timer: null,
				loading:false
			}
		},
		watch:{
			isPass(newVal,oldVal){
				let that = this;
				if(newVal){
					that.$nextTick(()=>{
						that.loadCode();
					})
					that.passName = "验证码登录";
				}else{
					that.passName = "密码登录";
				}
			}
		},
		methods:{
			findPass(){
				this.$router.push({
					name:'FindPass'
				})
			},
			submitFormPass(formName){
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
						if(!reg.test(that.ruleFormPass.urMobile)){ 
							that.$message.error('请输入正确的手机号码');
							return false;
						}
						if(that.ruleFormPass.urPassword == ""){
							that.$message.error('请输入密码');
							return false;
						}
						that.loadingLogin = true;
						this.$axios({
							method:'post',
							url:'user/ur/loginByPass',
							data:this.$qs.stringify(that.ruleFormPass)
						})
						.then((res)=>{
							let msg = res.data.msg;
							if(msg==2){
								let token = res.data.token;
								let urId = res.data.urId;
								let urName = res.data.urName;
								let urHead = res.data.urHead;
								window.localStorage.setItem("token",token);
								window.localStorage.setItem("urId",urId);
								window.localStorage.setItem("urName",urName);
								window.localStorage.setItem("urHead",urHead);
								that.$router.push({ name:'Main.User'});
							}else{
								that.$message.error(msg);
							}
							that.loadingLogin = false;
						})
						.catch((err)=>console.log('请求失败',err))
					} else {
						return false;
					}
				});
			},
			submitForm(formName){
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
						if(!reg.test(that.ruleFormCode.ceMobile)){ 
							that.$message.error('请输入正确的手机号码');
							return false;
						}
						if(that.ruleFormCode.code == ""){
							that.$message.error('请输入手机验证码');
							return false;
						}
						if(!this.isBreak){
							that.$message.error('请勾选下方隐私协议');
							return false;
						}
						that.loading = true;
						this.$axios({
							method:'post',
							url:'user/ur/loginByCode',
							data:this.$qs.stringify({
								urMobile: that.ruleFormCode.ceMobile,
								code: that.ruleFormCode.code
							})
						})
						.then((res)=>{
							let msg = res.data.msg;
							if(msg==2){
								let token = res.data.token;
								let urId = res.data.urId;
								let urName = res.data.urName;
								let urHead = res.data.urHead;
								window.localStorage.setItem("token",token);
								window.localStorage.setItem("urId",urId);
								window.localStorage.setItem("urName",urName);
								window.localStorage.setItem("urHead",urHead);
								window.localStorage.setItem("selectMenu","/Setting/Basic");
								window.localStorage.setItem("selectName","基本信息");
								that.$router.push({ name:'Main.User'});
							}else{
								that.$message.error(msg);
							}
							that.loading = false;
						})
						.catch((err)=>console.log('请求失败',err))
					} else {
						return false;
					}
				});
			},
			slideBtn(){
				let that = this;
				let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
				if(!reg.test(that.ruleFormCode.ceMobile)){ 
					that.$message.error('请输入正确的手机号码');
					return false;
				} 
				that.dialogVisible = true;
				that.$nextTick(()=>{
					AWSC.use("nc", function (state, module) {
						window.nc = module.init({
							appkey: "FFFF0N0000000000AA0F",
							scene: "nc_login",
							renderTo: "nc",
							width:450,
							success: function (data) {
								that.sendSMS(data.sessionId,data.sig,data.token);
								that.dialogVisible = false;
							},
							fail: function (failCode) {
								 window.console && console.log(failCode)
								 window.nc.reset();
							},
							error: function (errorCode) {
								window.nc.reset();
								 window.console && console.log(errorCode)
							}
						});
					})
				})
			},
			sendSMS(sessionId,sig,token){
				let that =this;
				this.$axios({
					method:'post',
					url:'sys/ce/sendLogin',
					data:this.$qs.stringify({
						sessionId: sessionId,
						sig: sig,
						token: token,
						ceMobile: that.ruleFormCode.ceMobile
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg==2){
						that.$message.success("验证码发送成功，120秒内有效，请注意查收！");
						that.startCounter();
					}else{
						window.nc.reset();
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			startCounter(){
				let that = this;
				that.disableSend = true;
				that.timer = setInterval(()=> {
					that.sendTime --;
					that.sendText = this.sendTime+"秒后可重新发送";
					if(that.sendTime <= 0 ) {
						that.disableSend = false;
						clearInterval(that.timer);
						that.sendTime = 120;
						that.sendText = "发送验证码";
					}
				},1000);
			},
			loadCode(){
				let that = this;
				AWSC.use("nc", function (state, module) {
					window.nc = module.init({
						appkey: "FFFF0N0000000000AA0F",
						scene: "nc_login",
						renderTo: "login",
						width:450,
						success: function (data) {
							that.ruleFormPass.sessionId = data.sessionId;
							that.ruleFormPass.sig = data.sig;
							that.ruleFormPass.token = data.token;
							that.disabled = false;
						},
						fail: function (failCode) {
							 window.console && console.log(failCode)
							 window.nc.reset();
						},
						error: function (errorCode) {
							window.nc.reset();
							 window.console && console.log(errorCode)
						}
					});
				})
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.marginStyle = (h - 637)/2 + 'px';
			}
		},
		beforeUnmount() {
			try{
				clearInterval(this.timer);
			}catch(e){}
		},
		beforeMount(){
		  this.setHeight();
		},
		mounted(){
		  let that = this;
		  window.addEventListener('resize',that.setHeight(),false);
		},
		beforeDestroy(){
		  window.removeEventListener('resize',this.setHeight(),false)
		}
	}
</script>

<style scoped>
	.pass{
		width:50%;
		display: inline-block;
		vertical-align: text-top;
	}
	.findPass{
		text-align: right;
	}
	.loginBox{
		width:450px;
		height:400px;
		margin:0px auto;
	}
	.title{
		font-weight: 600;
		font-size: 24px;
		color: rgb(4, 17, 29);
		margin-bottom:15px;
	}
	.loginBox .el-button{
		height:40px;
		border-radius: 10px;
	}
	#nc_1__scale_text{
		background: #409eff !important;
	  /* 默认背景色 */
	}

	.nc_scale div.nc_bg {
		background: #409eff !important;
	  /* 滑过时的背景色 */
	}

	.nc_scale .scale_text2 {
		color: #fff !important;
	  /* 滑过时的字体颜色 */
	}

	.nc_scale span {
		border: 1px solid #ccc !important;
	}

	.errloading {
	  border: #faf1d5 1px solid !important;
	  color: #ef9f06 !important;
	}
</style>
